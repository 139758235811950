.section-cms .card-container .card.card-library_item .card-content .card-body>div.container-form {
  display: block !important;
  overflow: unset !important;
}

.home-page {

  .homepage-profile-content {
    position: relative;

    >.container {
      position: absolute;
      top: 10%;
      left: 10%;
      max-width: 50%;
      padding: 1em;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      gap: 2em;

      >div:first-child {
        color: white !important;

        h2,
        h3,
        h4 {
          color: white;
        }
      }



    }

    ;

    &-actions {
      display: flex;
      gap: 1em;
    }

  }

  .tool-parent {
    margin-top: 2em;
    margin-bottom: 4em;
    padding-bottom: 2em;
    border-bottom: 1px solid hsl(0, 0%, 90%);
  }

  .selected-assets {
    margin-top: 2em;
    h4 {
      margin-bottom: 1em;
    }
  }

}